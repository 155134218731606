body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:after,
:before {
  --tw-content: "";
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
}

body {
  margin: 0;
  line-height: inherit;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 0.06rem;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1 {
  color: #4A8CEA;
  text-align: center;
  font-size: 2rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.75rem;
}
h5 {
  font-size: 1.5rem;
}
h6 {
  font-size: 1.25rem;
}

a {
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
pre,
samp {
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button,
select {
  text-transform: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -0.125rem;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
}

fieldset,
legend {
  padding: 0;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

[role="button"],
button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}


.article-text-bold {
  font-size: 1.8rem;
  font-weight: bold;
  color: #0B0B0C;
}

.article-text {
  font-size: 1.8rem;
  color: #0B0B0C;
}

.article-text-list li {
  font-size: 1.6rem;
  color: #7E8596;
}

.article-text-list li span {
  font-size: 1.6rem;
  font-weight: bold;
  color: #7E8596;
}

.article-link {
  background: #4A8CEA;
  color: #ffffff;
  padding: 0.3rem 1rem 0 1rem;
  border-radius: 16px;
}

.article-link:hover {
  background: #091D56;
  color: #ffffff;
  padding: 0.3rem 1rem 0 1rem;
  border-radius: 16px;
}

.article-header-link {
  color: #4A8CEA;
}


.mb-5 {
  margin-bottom: 3rem;
}
.mb-0 {
  margin-bottom: 0;
}
.my-5 {
  margin-bottom: 3rem;
  margin-top: 3rem;
}
.mr-3 {
  margin-right: 1rem;
}
.ml-0 {
  margin-left: 0;
}
.my-0 {
  margin-bottom: 0;
  margin-top: 0;
}
.mt-3 {
  margin-top: 1rem;
}
.mt-sm-0 {
  margin-top: 0;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.pt-2 {
  padding-top: .5rem;
}


.text-center {
  text-align:center;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}


@media (max-width: 575.98px) {
  .article-text-bold,
  .article-text {
    font-size: 1.4rem;
  }

  .article-text-list li,
  .article-text-list li span {
    font-size: 1.4rem;
  }
}

@media (min-width: 767.98px) {
  .justify-content-md-start {
    justify-content: flex-start;
  }
}
